import Head from 'next/head';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Flex from '../../../styles/Flex';
import Spacing from '../../../styles/Spacing';
import Text from '../../../styles/Text';
import Spinner from '../../Spinner';

const LogoutTemplate: React.FC = () => {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Flex vertical full alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
        <div>
          <Spinner.Default size="3em" color="primary" />
          <Spacing marginTop="sp24">
            <Text.Paragraph align="center">
              <FormattedMessage id="common.loading" />
            </Text.Paragraph>
          </Spacing>
        </div>
      </Flex>
    </>
  );
};

export default LogoutTemplate;
