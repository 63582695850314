import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useLocale } from '../../src/components/Providers/localeProvider';
import LoginTemplate from '../../src/components/Templates/Login';
import logging from '../../src/utils/loggingUtils';

type LoginContainerProps = Record<string, unknown>;

const LoginContainer: NextPage<LoginContainerProps> = () => {
  const router = useRouter();

  const locale = useLocale();

  useEffect(() => {
    // The last page the user was at before logging in
    const redirectTo =
      (router.query?.redirectTo as string | undefined) || new URL(locale, window?.location?.origin).toString();

    // Creates the local backend url
    let loginUrl: string | undefined;
    try {
      const loginUrlClass = new URL('/api/login', window?.location?.origin);
      if (redirectTo) {
        loginUrlClass.searchParams.append('redirectTo', redirectTo);
      }
      loginUrl = loginUrlClass.toString();
    } catch (err) {
      loginUrl = encodeURIComponent(
        `${window?.location?.origin}/api/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`
      );
      logging.warn('Did not manage to create url using URL class, using string composition fallback', {
        err,
        fallbackUrl: loginUrl
      });
    }

    router.replace(loginUrl, loginUrl);
  }, [locale, router]);

  return <LoginTemplate />;
};

export default LoginContainer;
